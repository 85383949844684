<template>
  <popup-base id="embed" class="ex">
    <div class="">
<!--      <div style="margin: auto" class="h-screen w-full"></div>-->
    </div>
  </popup-base>
</template>

<script>
import PopupBase from "./popup";
import http from "../../plugins/http";
import apiroute from "../../router/api-routes";
export default {
  name: "fedapay-popup",
  components: {PopupBase},
  props: {
    pack: null,
    names: null,
    email: null,
    zone: null,
    lang: null,
    phone: null,
    subject: null
  },
  mounted () {
    if (this.$store.getters.idFedaPay === '') {
      this.fedapay()
    } else if (this.$store.getters.idFedaPay !== '') {
      this.sendEmailToAll(this.$store.getters.idFedaPay)
    }
    /*const contentMail = 'Nom et prénoms: ' + vm.names + '; Email: ' + vm.email + '; Sujet de recheche: ' + vm.subject + '; Téléphone: ' + vm.phone + '; Préférence de langue: ' + vm.lang + '; Zone géographique: ' + vm.zone + "; Pack d'assistance: " + vm.pack + 'xof' + '; Payment Id: ' + payment;
    http.post(apiroute.baseURL + apiroute.sendEmail, {
      receiver: 'richahounou@gmail.com',
      sender: 'contact@41devs.com',
      senderName: 'REMA',
      object: 'Demande de revue de littérature médicale',
      content: contentMail
    }).then(response => {
      console.log(response)
    }).catch(error => {
      console.log(error);
    })*/
  },
  methods: {
    fedapay () {
      const vm = this
      let payment = ''
      FedaPay.init({
        public_key: 'pk_live_m7Jjgg6iYLG8vKlwyD7LBRFn',
        transaction: {
          amount: this.pack,
          description: 'Description'
        },
        customer: {
          email: this.email,
          lastname: this.names,
          firstname: this.names,
        },
        onComplete: (response => {
          vm.$store.dispatch('CREATE_FEDAPAY', response.transaction.transaction_key)
          console.log(response)
          console.log(response.transaction)
          payment = response.transaction.transaction_key
          // alert(response.transaction.transaction_key)

          vm.sendEmailToAll(response.transaction.transaction_key)
        }),
        container: '#embed'
      });
    },
    sendEmailToAll (item) {
      // alert('okk')
      let vm = this;
      const email = vm.email;
      const contentMail = '<b>Nom et prénoms: </b>' + this.$store.getters.clientInfos.names + '; <br /><b>Email: </b>' + this.$store.getters.clientInfos.email + '; <br /><b>Sujet de recheche: </b>' + this.$store.getters.clientInfos.subject + '; <br /><b>Téléphone: </b>' + this.$store.getters.clientInfos.phone + '; <br /><b>Préférence de langue: </b>' + this.$store.getters.clientInfos.lang + '; <br /><b>Zone géographique: </b>' + this.$store.getters.clientInfos.zone + "; <br /><b>Pack d'assistance: </b>" + this.$store.getters.clientInfos.pack + 'xof' + '; <br /><b>Payment Id: </b>' + item;
      http.post(apiroute.baseURL + apiroute.sendEmail, {
        receiver: 'contact@remaapp.com',
        sender: 'contact@41devs.com',
        object: 'Demande de revue de littérature médicale',
        content: contentMail
      }).then(response => {
        console.log(response)
        /* this.invalidSubmit = false;
        vm.names = '';
        vm.email = '';
        vm.subject = '';
        vm.phone = '';
        vm.lang = '';
        vm.zone = '';
        vm.countryCode = '';
        vm.pack = null;
        vm.terms = false; */

      }).catch(error => {
        console.log(error);
      })
    },
    closeFedapay () {
      this.$emit('closeFedapay', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  #embed {
    .modal__iframe ::v-deep {
      .modal-open {
        .modal {
          .modal-dialog {
            margin-top: 5rem !important;
          }
        }
      }
    }
  }
</style>
