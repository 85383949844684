<template>
  <main :class="{'main': borderActive === true}" class="card flex h-full items-center lg:justify-around lg:flex-col flex-row mb-10">
    <svgIcon :name="iconUrl" v-if="iconUrl === 'ic_soleil1'" class="h-8 lg:ml-0 ml-5 mt-0 svg-fill" original></svgIcon>
    <svgIcon :name="iconUrl" v-else class="h-12 lg:ml-0 ml-8 mt-0 svg-fill" original></svgIcon>
    <div class="flex flex-col lg:ml-0 ml-10 lg:justify-around lg:items-center">
    <p class="lg:text-3xl font-bold text-xl text-black lg:mt-2">{{ price }}</p>
    <p class="text-gray-600 mt-4 text-sm font-bold lg:mt-8"> {{ pack }}</p>
    </div>
    <div class="responsive border ml-auto flex  mr-5 border-green-rema_color h-5 w-5 border-4 rounded-full">
      <div v-if="boxActive" class="border bg-green-rema_color mt-auto mb-auto border-green-rema_color m-auto rounded-full h-4 w-4"></div>
    </div>
  </main>
</template>

<script>
export default {
  props: {
    iconUrl: {
      type: String
    },
    price: {
      type: String
    },
    pack: {
      type: String
    },
    boxActive: {
    type: Boolean
    },
    borderActive: {
      type: Boolean
    },
  }
}
</script>

<style scoped>
.svg-fill {
  fill: green;
}

@media (max-width: 1024px) {
  main {
    width: 90vw;
    height: 22vw;
    left: 344px;
    top: 2292px;

    border: 1px solid #CECECE;
    box-sizing: border-box;
    border-radius: 14px;
  }

  .main {
    width: 90vw;
    height: 22vw;
    left: 344px;
    top: 2292px;

    border: 2px solid #16B853;
    box-sizing: border-box;
    border-radius: 14px;
  }
}
@media (min-width: 1024px) {
  main {
    width: 15vw;
    height: 15vw;
    left: 344px;
    top: 2292px;

    border: 1px solid #CECECE;
    box-sizing: border-box;
    border-radius: 14px;
  }

  .main {
    width: 15vw;
    height: 15vw;
    left: 344px;
    top: 2292px;

    border: 4px solid #16B853;
    box-sizing: border-box;
    border-radius: 14px;
  }
}

</style>
