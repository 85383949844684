<template>
  <div class="h-full text-black justify-between items-center flex flex-col">
    <div class="text-4xl m-2 text-center font-bold">Comment ça marche ?</div>
    <div class="lg:text-2xl text-xl mt-10 mb-20 font-medium w-3/4 text-center">Soumettez votre demande via le formulaire ci-dessous et recevez des références scientifiques pertinentes sur votre sujet de recherche médicale en 24h à 48h.</div>
    <formulaire></formulaire>
  </div>
</template>

<script>
import Formulaire from '../UI/Formulaire'

export default {
  components: {
    Formulaire
  },
}
</script>

<style>
</style>
