<!--
About Page
Created at 09/04/2021 11:45
Author: Kevin Nassara
!-->
<template>
  <div class="">
    <Header></Header>
    <div class="">
    <transition>
      <MenuBurger v-if="$store.state.active === true"></MenuBurger>
    </transition>
    <div @click="$store.state.active = false"><Presentation></Presentation></div>
      <div @click="$store.state.active = false"><Packs></Packs></div>
      <div @click="$store.state.active = false"><Form id="form"></Form></div>
    </div>
    <div @click="$store.state.active = false"><Footer></Footer></div>
  </div>
</template>

<script>
import Header from '../../components/helper/Header'
import Footer from '../../components/helper/Footer'
import Presentation from '../../components/main_components/Presentation'
import Packs from '../../components/main_components/Packs'
import Form from '../../components/main_components/Form'
import MenuBurger from "../../components/UI/MenuBurger";

export default {
  name: 'index',
  components: {
    MenuBurger,
    Form,
    Packs,
    Presentation,
    Footer,
    Header
  },
  data() {
    return {
      activate: false,
    }
  },
  methods: {
    setActivate() {
      this.activate = !this.activate
    }
  }
}
</script>

<style lang="scss" scoped>
.v-enter-from {
  opacity: 0;
  transform: translate(-1000px);
}

.v-enter-active {
  transition: all 4s ease-out;
}

.v-enter-to {
  opacity: 1;
  transform: translate(0);
}

.v-leave-from {
  opacity: 1;
  transform: translateX(0);
}

.v-leave-active {
  transition: all 0.7s ease-in;
}

.v-leave-to {
  opacity: 0;
  transform: translateX(-200px);
}
</style>
