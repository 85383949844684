<template>
  <div class="flex m-5 h-full mb-32 flex-col items-center mt-32">
    <div class="text-black text-center text-4xl font-bold">Découvrez nos packs d’assistance</div>
    <div class="flex justify-around w-full mt-12 flex-col items-center lg:flex-row">
      <button  @click="setSelectedBasique">
        <pack-components :class="{'active': activateBasic}" :icon-url="'ic_soleil1'" :pack-name="'Basic'" :price="'2500'"></pack-components>
      </button>
      <button class="lg:mt-0 mt-5" @click="setSelectedPro">
        <pack-components :class="{'active': activatePro}" :icon-url="'ic_soleil2'" :pack-name="'Pro'" :price="'5000'"></pack-components>
      </button>
      <button class="lg:mt-0 mt-5" @click="setSelectedExpert">
        <pack-components :class="{'active': activateExpert}" :icon-url="'ic_soleil3'" :pack-name="'Expert'" :price="'10000'"></pack-components>
      </button>
    </div>
  </div>
</template>

<script>
import PackComponents from '../UI/PackComponents'
export default {
  components: {
    PackComponents
  },
  data() {
    return {
      activateBasic: false,
      activatePro: false,
      activateExpert: false
    }
  },
  methods:{
    setSelectedBasique () {
      this.activateBasic = true
      this.activatePro = false
      this.activateExpert = false
    },
    setSelectedPro () {
      this.activatePro = true
      this.activateBasic = false
      this.activateExpert = false
    },
    setSelectedExpert () {
      this.activateExpert = true
      this.activateBasic = false
      this.activatePro = false
    }
  }
}
</script>

<style scoped>
@media (min-width: 1024px) {
  .active {
    width: 316px;
    height: 326px;
    left: 178px;
    top: 939px;

    background: #FFFFFF;
    border: 2px solid #124C7D;
    box-sizing: border-box;
    box-shadow: 0px 0px 30px rgba(118, 118, 118, 0.15);
    border-radius: 18px;
  }

}
@media (max-width: 1024px) {
  .active {
    width: 290px;
    height: 326px;
    left: 178px;
    top: 939px;

    background: #FFFFFF;
    border: 2px solid #124C7D;
    box-sizing: border-box;
    box-shadow: 0px 0px 30px rgba(118, 118, 118, 0.15);
    border-radius: 18px;
  }
}
</style>
