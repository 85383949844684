<template>
  <base-card class="flex flex-col">
    <div class="flex flex-row h-full items-center w-2/5 m-auto">
      <svgIcon v-if="iconUrl === 'ic_soleil1'" :name="iconUrl" original class="h-6 mt-6 m-auto"></svgIcon>
      <svgIcon v-else :name="iconUrl" original class="h-10 mt-5 m-auto"></svgIcon>
      <p class="mr-auto text_color text-xl font-medium">{{ packName }}</p>
    </div>
    <div class="mt-10 text-black text-2xl font-bold text-center">{{ price }} xof</div>
    <div class="flex mt-12 flex-col justify-around h-full text-gray-600 items-center mt-8">
      <div class="flex flex-row items-center w-3/4">
        <svgIcon name="ic_check" class="h-6" original></svgIcon>
        <p class="ml-3" v-if="packName === 'Basic'">Recevez  <strong>3</strong> références</p>
        <p class="ml-3" v-if="packName === 'Pro'">Recevez  <strong>7</strong> références</p>
        <p class="ml-3" v-if="packName === 'Expert'">Recevez  <strong>15</strong> références</p>
      </div>
      <div class="flex items-center flex-row w-3/4 mt-3">
        <svgIcon name="ic_check" class="h-6" original></svgIcon>
        <p class="ml-3">Documents au format PDF</p>
      </div>
      <div class="flex items-center flex-row w-3/4  mt-3">
        <svgIcon name="ic_check" class="h-6" original></svgIcon>
        <p class="ml-3">Traduction complète incluse</p>
      </div>
    </div>
  </base-card>
</template>

<script>
import BaseCard from '../UI/BaseCard'

export default {
  components: {
    BaseCard
  },
  props: ['packName', 'iconUrl', 'price', 'checkIcon']
}
</script>

<style>
.text_color {
  color: #124C7D;
}
</style>
