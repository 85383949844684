<template>
  <div class="lg:h-screen h-full  mb-5 items-center flex lg:flex-row flex-col justify-around">
    <div class="flex flex-col m-10 mt-32 lg:w-1/2 justify-around">
      <div style="color: #16B853" class="leading-relaxed text-green-500 lg:ml-12 text-4xl lg:text-5xl  font-extrabold">
        Commandez votre revue de littérature médicale en quelques clics !
      </div>
      <div style="color: #474748" class="lg:ml-10 leading-10 mt-12 text-2xl">
        Allez plus vite dans vos travaux scientifiques en sollicitant l’aide de notre assistante de recherche médicale 7j/7
      </div>
      <button class="border lg:ml-10 border-green-rema_color text-xl mt-16 bg-green-rema_color rounded-md text-white h-16 font-bold w-56"><a href="#form" class="text-white">Commander</a></button>
    </div>
    <div class="lg:w-1/2 w-5/6">
      <svgIcon name="ic_pana" original class="image lg:mr-16 mb-5 lg:mt-32"></svgIcon>
     <!-- <img  src="src/assets/images/png/img_livres.png"/> -->
    </div>
    <!--<div class="flex flex-col w-full mr-2 mt-32">
      <button class="w-40 bg-green-rema_color rounded-full h-40 mt-5 ml-auto mr-16"></button>
      <svgIcon name="ic_ellipse" orginal class="svg-fill h-32 mr-auto mt-48"></svgIcon>
      <img class="absolute mt-10" src="../../assets/images/png/img_livres.png" alt="livres">
    </div> -->
  </div>
</template>

<style scoped>
 div {
   background: #F0FFF4;
 }
 .image {
  height: 45%;
 }
 img {
   width: 573px;
   height: 379px;
   left: 700px;
 }
 .interligne {
   line-height: 5rem;
 }
 .svg-fill {
   fill: #FFC000;
 }
 .color {
   color: #16B853
 }
</style>

<script>
import BaseButton from '../UI/BaseButton'
export default {
  components: {
    BaseButton
  }
}
</script>
