<template>
  <div class="fixed flex flex-col items-center w-2/3 h-screen bg-green-100 slide responsive ">
    <div class="flex flex-col height">
    <div class="flex flex-col mt-10 text-gray-600">
      <p>contact@remaapp.com</p>
      <p class="mt-2 text-center">+229 96508130</p>
    </div>
    <button class="mt-16 ml-auto mr-auto">
      <a   href="https://api.whatsapp.com/send?phone=22996508130&text="
           class="float"
           target="_blank">
      <svgIcon name="ic_whatsapp" class="h-20" original>
      </svgIcon>
      </a>
    </button>
    <button class="w-40 h-10 mt-16 font-bold text-white border rounded-md border-green-rema_color bg-green-rema_color"><a class="text-white" href="https://www.remaapp.com/"> Visiter le site</a></button>
    </div>
  </div>
</template>

<style>
.height {
  height: 50%;
}
@media (min-width: 1024px) {
  .responsive {
    display: none;
  }
}
.block {
  transform: translateX(150px);
}
.slide {
  box-shadow: 10px 10px 10px 500px rgba(0, 0, 0, 0.12);
  animation-name: slidein;
  animation-duration: 0.5s;
}
@keyframes slidein {
  from {
    width: 0%;
  }
  to {
    width: 66.666667%;
  }
}
</style>
<script>
import BaseButton from "./BaseButton";
export default {
  components: {BaseButton},
  data () {
    return {
      activate: true
    }
  }
}
</script>
