<template>
  <nav>
    <div class="h-20 color">
    <slot></slot>
    </div>
  </nav>
</template>

<script>
export default {
  data () {
    return {
      activate: false
    }
  }
}
</script>

<style>
.color {
  background: #E0EEFF;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}
@media(max-width: 1024px) {
  nav {
    width: 290px;
    height: 326px;
    left: 178px;
    top: 939px;

    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: 0px 0px 30px rgba(118, 118, 118, 0.15);
    border-radius: 18px;
  }
}

@media (min-width: 1024px) {
  nav {
    width: 23vw;
    height: 24vw;
    /*  width: 316px;
  height: 326px;*/
    left: 178px;
    top: 939px;

    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: 0px 0px 30px rgba(118, 118, 118, 0.15);
    border-radius: 18px;
  }
}
.police {
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');
  font-family: 'Roboto', sans-serif;
}
</style>
