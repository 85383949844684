<template>
  <div class="flex header absolute w-full bg-white justify-between border shadow-2xl items-center h-24 flex-row">
    <div class="lg:ml-24 ml-10">
      <svgIcon name="ic_rema_logo" class="lg:h-16 h-12" original></svgIcon>
    </div>
    <div class="flex-row normal flex justify-between">
      <div class=" flex flex-row ml- mr-8 justify-between">
        <div class="text-gray-600 w- flex flex-col">
          <p>contact@remaapp.com</p>
          <p class="text-right">+229 96508130</p>
        </div>
        <div class="h-10 border ml-5 border-gray-500 "></div>
        <a
          href="https://api.whatsapp.com/send?phone=22996508130&text="
          class="float"
          target="_blank"
        >
          <svgIcon name="ic_whatsapp" class=" ml-5 h-12" original></svgIcon>
        </a>
      </div>
      <button class="police ml-0 rounded-md bg-green-rema_color border-green-rema_color border w-40  text-white text-sm font-semibold mr-24"><a class="text-white" href="https://www.remaapp.com/">Visiter le site</a></button>
    </div>
    <button  class="mr-16 bg-green-rema_color flex mt-8 responsive">
<!--      <input type="checkbox" id="menu_checkbox"> -->
      <label @click="setActive" for="menu_checkbox">
        <div></div>
       <div></div>
       <div></div>
      </label>
   </button>
  </div>
</template>

<script>
import BaseButton from '../UI/BaseButton'

export default {
  components: {
    BaseButton
  },
  methods: {
    setActive() {
      this.$store.state.active = this.$store.state.active !== true;
      console.log(this.$store.state.active);
    }
  }
}
</script>

<style scoped>
@media (max-width: 1024px) {
  .normal {
    display: none;
  }
}
@media (min-width: 1024px) {
  .responsive {
    display: none;
  }
}
.header {
  box-shadow: 1px 2px 15px 2px rgba(0, 0, 0, 0.12);
}
#menu_checkbox
{
  display: none;
}

label
{
  display: block;
  width: 35px;
  height: 15px;
  margin:  -30px;
}

label:before
{
  content: "";
  position: fixed;
}

label div
{
  position: relative;
  top: 0;
  height: 3px;
  background-color: #fff;
  margin-bottom: 7px;
  transition: 0.3s ease transform, 0.3s ease top, 0.3s ease width, 0.3s ease right;
  border-radius: 1000px;
}

label div:first-child
{
  transform-origin: 0;
  background-color: black;
}

label div:last-child
{
  margin-bottom: 0;
  transform-origin: 60px;
  background-color: black;
}

label div:nth-child(2)
{
  right: 0;
  background-color: black;
}

#menu_checkbox:checked + label:before
{
  background-color: #4caf50;
}

#menu_checkbox:checked + label div:first-child
{
  top: -5px;
  width: 30px;
  transform: rotateZ(45deg);
}

#menu_checkbox:checked + label div:last-child
{
  top: 35px;
  width: 20px;
  transform: rotateZ(45deg);
}

#menu_checkbox:checked + label div:nth-child(2)
{
  width: 48px;
  top: 2px;
  left: -9px;
  right: 13px;
  transform: rotateZ(-45deg);
}
</style>
