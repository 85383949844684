<template>
  <form @submit.prevent="SubmitData" class="flex flex-col width items-cente">
    <label for="NomPrenom" class="m-auto width">
      <input :class="{'border-red-500': invalidName}" class="w-full h-16 px-5 mt-0 border border-gray-500 rounded-md" @blur="validateName(names)" v-model="names"
      type="text" name="NomPrenom" placeholder="Prénoms et Nom" />
      <p class="text-red-500" v-if="invalidName">Veuillez remplir le champ</p>
    </label>
    <label class="m-auto width">
      <input :class="{'border-red-500': invalidEmail}" class="w-full h-16 px-5 mt-12 border border-gray-500 rounded-md" @blur="validateEmail(email)" v-model="email"
      type="email" name="email" placeholder="Email" />
      <p class="text-red-500" v-if="invalidEmail">Veuillez remplir le champ</p>
    </label>
    <label class="m-auto width">
      <textarea rows="5" :class="{'border-red-500': invalidSubject}" class="w-full px-5 py-6 mt-12 border border-gray-500 rounded-md h-" @blur="validateSubject(subject)" v-model="subject"
      type="text" name="subject" placeholder="Sujet de recherche" />
      <p class="text-red-500" v-if="invalidSubject">Veuillez remplir le champ</p>
    </label>
    <label :class="{'border-red-500': invalidPhone}" class="flex flex-col h-16 px-5 py-4 m-auto mt-12 border border-gray-500 rounded-md width">
      <div class="flex flex-row town">
        <vue-country-code class="countryCode"
          @onSelect="onSelect"
          :enabledCountryCode="true"
          :enableSearchField="true"
          :preferredCountries="['bj', 'tg', 'ci', 'sn']"
          :enabledFlags="true">
        </vue-country-code>
        <div class="w-0 h-8 ml-2 border border-l-0 border-gray-500"></div>
      <input class="ml-5 bg-transparent"  @blur="validatePhone(phone)" v-model="phone"
      type="tel" name="phone" placeholder="Téléphone" />
      </div>
      <p class="mt-5 text-red-500" v-if="invalidPhone">Veuillez remplir le champ</p>
    </label>
    <label class="flex flex-row h-16 m-auto mt-12 border border-gray-500 rounded-md width">
      <select v-model="lang" name="lang" class="absolute h-16 px-5 text-gray-600 bg-transparent rounded-md width_select">
        <option value="Pas de reference">Pas de préférence</option>
        <option value="Francais">Français</option>
        <option value="English">Anglais</option>
      </select>
      <label v-if="lang === ''" class="mt-5 ml-5 text-gray-500">Préférence de langue</label>
      <svgIcon class="h-5 mt-5 ml-auto mr-5 margin_select" original name="ic_select"></svgIcon>
    </label>
    <label class="flex flex-row h-16 m-auto mt-12 border border-gray-500 rounded-md width">
      <select v-model="zone" name="zone" class="absolute h-16 px-5 text-gray-600 bg-transparent rounded-md width_select">
        <option value="Tout">Pas de préférence</option>
        <option value="Afrique">Afrique</option>
        <option value="Europe">Europe</option>
        <option value="Amérique">Amérique</option>
      </select>
      <label v-if="zone === ''" class="mt-3 ml-5 text-gray-500 lg:mt-5">Zone géographique prioritaire des références</label>
      <svgIcon class="h-5 mt-5 ml-auto mr-5 margin_select" original name="ic_select"></svgIcon>
    </label>
    <label class="flex flex-col m-auto mt-10 lg:w-full">
      <p class="m-auto mb-10">Choisissez un pack d’assistance</p>
      <div class="flex flex-col justify-between lg:flex-row w-">
        <div @click="setSelectedBasique" value="basique">
        <mini-pack-components :border-active="borderActive1" :box-active="borderActive1" :icon-url="'ic_soleil1'" :price="'2500 xof'" :pack="'Basic'"></mini-pack-components>
        </div>
        <div @click="setSelectedPro" value="pro">
          <mini-pack-components :border-active="borderActive2" :box-active="borderActive2" :icon-url="'ic_soleil2'" :price="'5000 xof'" :pack="'Pro'"></mini-pack-components>
        </div>
        <div @click="setSelectedExpert" value="expert">
          <mini-pack-components :border-active="borderActive3" :box-active="borderActive3" :icon-url="'ic_soleil3'" :price="'10000 xof'" :pack="'Expert'"></mini-pack-components>
        </div>
      </div>
    </label>
    <label class="flex flex-col items-start mt-5 lg:ml-40">
      <div class="flex items-start">
        <input type="checkbox" v-model="terms" class="mt-1" />
        <div class="items-start w-4/5 ml-5 leading-6 text-left">
          En soumettant votre demande vous acceptez
          <a href="http://ressources.remaapp.com/TermsOfService" style="color: #16B853">nos conditions générales d’utilisation</a>
          et
          <a href="http://ressources.remaapp.com/privacyPolice" style="color: #16B853"> politique de confidentialité</a>
       </div>
      </div>
      <p v-if="terms === false" class="mt-5 ml-5 text-red-500">Veuillez accepter les conditions pour soumettre</p>
    </label>
    <fedapay-popup v-if="feda" :email="email" :pack="pack" :names="names" :zone="zone" :lang="lang" :phone="phone" :subject="subject" @getFedapayId="getFedapayId" @closeFedapay="closeFedapay"/>
    <base-button id="pay-btn" :class="{'animation': invalidSubmit}" class="h-12 m-auto mt-10 mb-20 font-semibold text-white">Soumettre</base-button>
    <p v-if="invalidSubmit === true" class="m-auto mb-12 text-red-500">Veuillez remplir tous les champs</p>
    <Dialog @CloseDialog="desactiveDialog" v-if="activatePopup"></Dialog>
  </form>
</template>

<script>
import MiniPackComponents from './MiniPackComponents'
import BaseButton from './BaseButton'
import apiroute from '../../router/api-routes'
import http from "../../plugins/http"
import Dialog from "./Dialog";
import FedapayPopup from "./fedapay-popup";

export default {
  components: {
    FedapayPopup,
    Dialog,
    BaseButton,
    MiniPackComponents,
  },
  computed: {
    embedStyle() {
      if (!this.embed) {
        return 'width: 800px; height: 1px';
      } else {
        return 'height: 700px';
      }
    }
  },
  data() {
    return {
      countryCode : '', country: '', borderActive1: false,
      borderActive2: false, borderActive3: false,
      activateBasic: false, activatePro: false,
      activateExpert: false, invalidName: false,
      invalidEmail: false, invalidSubject: false,
      invalidPhone: false, invalidSubmit: false,
      names: '',  email: '', subject: '', phone: '',
      lang: '', zone: '', pack: 1, terms: false,
      transitionId: null, activatePopup: false,
      embed: false, feda: false, fedaId: null
    };
  },
  mounted () {
    if (this.$store.getters.idFedaPay !== '') {
      this.sendEmailToAll(this.$store.getters.idFedaPay)
    }
  },
  methods: {
    getFedapayId (val) {
      this.fedaId = val
    },
    closeFedapay (val) {
      this.feda = val
    },
    desactiveDialog() {
      this.activatePopup = false
    },
    onSelect( {name, iso2, dialCode} ) {
      this.countryCode = dialCode;
      this.country = iso2;
      console.log(name, iso2, dialCode);
    },
    windowTerms() {
      window.open('https://kkiapay.me/termes-and-conditions/')
    },
    validateName(input) {
      this.invalidName = input.trim() === '';
    },
    validateEmail(input) {
      this.invalidEmail = input.trim() === '';
    },
    validateSubject(input) {
      this.invalidSubject = input.trim() === '';
    },
    validatePhone(input) {
      this.invalidPhone = input.trim() === '';
    },
    setSelectedBasique() {
      this.activateBasic = true
      this.activatePro = false
      this.activateExpert = false
      this.pack = 2500;
      this.borderActive1 = true
      this.borderActive2 = false
      this.borderActive3 = false
    },
    setSelectedPro() {
      this.activatePro = true
      this.activateBasic = false
      this.activateExpert = false
      this.pack = 5000
      this.borderActive1 = false
      this.borderActive2 = true
      this.borderActive3 = false
    },
    setSelectedExpert() {
      this.activateExpert = true
      this.activateBasic = false
      this.activatePro = false
      this.pack = 10000
      this.borderActive1 = false
      this.borderActive2 = false
      this.borderActive3 = true
    },
    SubmitData() {
      const vm = this;
      if (this.names === '' ||
        this.email === '' ||
        this.subject === '' ||
        this.phone === '' ||
        this.terms === false) {
        this.invalidName = true
        this.invalidEmail = true
        this.invalidSubject = true
        this.invalidPhone = true
        this.invalidSubmit = true
        // this.embed = false;
      } else {
        this.invalidSubmit = false;
        const client = {}
        client.names = vm.names
        client.email = vm.email
        client.pack = vm.pack
        client.zone = vm.zone
        client.lang = vm.lang
        client.phone = vm.phone
        client.subject = vm.subject
        vm.$store.dispatch('CREATE_INFOS', client)
        if (this.country === 'TG' || this.country === 'SN' || this.country === 'NE') {
          this.feda = true
          // vm.sendEmailToAll(vm.fedaId);
        } else {
          openKkiapayWidget({
            amount: this.pack,
            position: 'center',
            callback: '',
            data: '',
            theme: 'green',
            key: '5bb422a644f4ea965ea1d58f94c0f1b2cfba737d'
          });
        }
        addSuccessListener(response => {
          console.log(response);
          vm.transitionId = response.transitionId;
          vm.sendEmailToAll(response.transactionId);
          vm.activatePopup = true;
        });
        this.phone = this.countryCode + this.phone;
        console.log('name: ' + this.names);
        console.log('email: ' + this.email);
        console.log('subject: ' + this.subject);
        console.log('phone: ' + this.phone);
        console.log('lang: ' + this.lang);
        console.log('zone: ' + this.zone);
        console.log('pack: ' + this.pack);
      }
    },
    sendEmailToAll (item) {
      let vm = this;
      const email = vm.email;
      const contentMail = '<b>Nom et prénoms: </b>' + this.$store.getters.clientInfos.names + '; <br /><b>Email: </b>' + this.$store.getters.clientInfos.email + '; <br /><b>Sujet de recheche: </b>' + this.$store.getters.clientInfos.subject + '; <br /><b>Téléphone: </b>' + this.$store.getters.clientInfos.phone + '; <br /><b>Préférence de langue: </b>' + this.$store.getters.clientInfos.lang + '; <br /><b>Zone géographique: </b>' + this.$store.getters.clientInfos.zone + "; <br /><b>Pack d'assistance: </b>" + this.$store.getters.clientInfos.pack + 'xof' + '; <br /><b>Payment Id: </b>' + item;

      http.post(apiroute.baseURL + apiroute.sendEmail, {
        receiver: 'contact@remaapp.com',
        sender: 'contact@41devs.com',
        object: 'Demande de revue de littérature médicale',
        content: contentMail
      }).then(response => {
        console.log(response)
        this.invalidSubmit = false;
        vm.names = '';
        vm.email = '';
        vm.subject = '';
        vm.phone = '';
        vm.lang = '';
        vm.zone = '';
        vm.countryCode = '';
        vm.pack = null;
        vm.terms = false;
        vm.$store.dispatch('CREATE_INFOS', null)
        vm.$store.dispatch('CREATE_FEDAPAY', '')

      }).catch(error => {
        console.log(error);
      })


      /*const api_key = 'pubkey-84d65a12b9b722009663ca84462d2641';
      const domain = 'ads.remaapp.com';

      const axios = require('axios');
      const qs = require('qs');
      const data = qs.stringify({
        'from': '\'Excited user <mailgun@remaapp.com>\'',
        'to': 'richahounou@gmail.com',
        'subject': '\'Hello\'',
        'text': '\'Testing some Mailgun awesomeness!\''
      });
      const config = {
        method: 'post',
        url: `https://api.mailgun.net/v3/${domain}/messages`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: new URLSearchParams({ data })
      };

      axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
          })
          .catch(function (error) {
            console.log(error);
          });*/

      /*http({
        url: `https://api.mailgun.net/v3/${domain}/messages`,
        method: 'POST',
        auth: {
          username: 'api',
          password: api_key, //using firebase functions here
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: new URLSearchParams({
          from: `"Foo bot" <noreply@remaapp.com>`,
          to: 'richahounou@gmail.com',
          subject: "Hello",
          template: 'my_template',
        })
      })*/
    },
  },
}
</script>

<style>
.countryCode/deep/ div {
 border: none;
}
@media (min-width: 1024px) {
  .width {
    width: 65%;
  }
  .height {
    height: 1000px;
  }
  .width_select {
    width: 42%;
  }
}

@media (max-width: 1024px) {
  .width {
    width: 90%;
  }
  .height {
    height: 1000px;
  }
  .width_select {
    width: 80%;
  }
}

.activePack {
  width: 226px;
  height: 221px;
  left: 344px;
  top: 2292px;

  border: 2px solid #16B853;
  box-sizing: border-box;
  border-radius: 14px;
}
select{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
a {
  color: #16B853;
}
</style>
